import React from 'react'
import RightBoxAuth from '../../global/RightBoxAuth'
import ResetPassComp from './ResetPassComp'
import { useTranslation } from 'react-i18next'

function AuthResetPass() {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex align-items-center">
        <RightBoxAuth title={t('auth.forgotPassword')} />
        <ResetPassComp />
      </div>
    </>
  )
}

export default AuthResetPass