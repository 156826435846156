import React, { useRef } from 'react'
import Landing from './../components/Landing/Landing'
import { useLocales } from './../locales';
import { Box, Button, TextField } from '@mui/material'
import './../components/Consulotion/consultationCard.css'
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import CountrySelect from '../components/glopal/CountrySelect'
import consulation from './../assets/AllImages/42.jpg'

function Consultations() {
  const { translate } = useLocales();
  const { t } = useTranslation()
  const form = useRef()
  const { register, handleSubmit, reset } = useForm();
  const sendEmail = (e) => {
    emailjs.sendForm('service_djp04na', 'template_ckolnbv', form.current, 'q-XEUYuNFtDjs2jyR')
      .then((result) => {
        console.log(result.text);
        enqueueSnackbar("Data sent successfully👍")
        reset()
      }, (error) => {
        console.log(error.text);
        enqueueSnackbar(error, { variant: 'error' });
        reset()
      });
    reset()
  }

  return (
    <>
      <Landing title={translate('consultations.bookConsultations')} bg={consulation} />

      <Box sx={{ width: '75%', margin: 'auto', pb: 2 }}>
        <Box component="form" gap={20} ref={form} onSubmit={handleSubmit(sendEmail)} mt={1} name="from_name" >
          <label for="name" style={{ padding: '10px' }} >{t("contactMe.name")} <span style={{ color: "red" }}>*</span> </label>
          <TextField
            id='name'
            // placeholder={t("contactMe.name") + ' **'}
            fullWidth
            name="name"
            {...register("name", { required: true })}
            required={true}
            error={false}
            sx={{
              mb: 1,
              backgroundColor: "#FFF",
            }}
          />
          <label for="email" style={{ padding: '10px' }} >{t("contactMe.email")} <span style={{ color: "red" }}>*</span> </label>
          <TextField
            id='email'
            // placeholder={t("contactMe.email")}
            fullWidth
            name="email"
            {...register("email", { required: true })}
            required
            sx={{
              mb: 1,
              backgroundColor: "#FFF",
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center' }} >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '60%' }} >
              <label for="phone" style={{ padding: '10px' }} >{t("contactMe.phone")} <span style={{ color: "red" }}>*</span> </label>

              <TextField
                id='phone'
                // fullWidth
                // placeholder={t("contactMe.phone")}
                required
                name="phone"
                {...register("phone", { required: true })}
                sx={{
                  mb: 1,
                  backgroundColor: "#FFF",

                }}
              >
              </TextField>
            </Box>
            <CountrySelect />
          </Box>
          <label for="message" style={{ padding: '10px' }} >{t("contactMe.message")} <span style={{ color: "red" }}>*</span> </label>
          <TextField
            fullWidth
            id='message'
            // placeholder={t("contactMe.message")}
            name="message"
            required
            {...register("message", { required: true })}
            sx={{
              mb: 1,
              backgroundColor: "#FFF",
            }}
          />

          <br />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            sx={{
              mt: 1,
              mb: 1,
              color: "white",
              fontSize: 18,
              background: '#232A6D'
            }}
          >
            {t("contactMe.sendMessage")}
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default Consultations