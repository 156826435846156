import { Typography } from '@mui/material'
import React from 'react'
function MyList({ one, tow, three, four, five, six, nameWorking, name }) {
    return (
        <>
            <ol style={{ fontWeight: 'bold' }} >
                <li style={{ fontWeight: 'bold' }} >{one}</li>
                <li style={{ fontWeight: 'bold' }} >{tow}</li>
                <li style={{ fontWeight: 'bold' }} >{three}</li>
                <li style={{ fontWeight: 'bold' }} >{four}</li>
                <li style={{ fontWeight: 'bold' }} >{five}</li>
                <li style={{ fontWeight: 'bold' }} >{six}</li>
            </ol>
            <Typography variant="body1" textAlign={"left"} sx={{ fontWeight: 'bold' }} >
                {nameWorking}
                <br />
                {name}
            </Typography>
        </>
    )
}

export default MyList