import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material'
const PagesFoteer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation()

    return (
        <>
            {/* <div className="col-md-1"></div> */}
            <div>
                <div className="box">
                    <h5 className="mb-2" style={{ fontWeight: '600', color: '#B38840' }}>{t('pages')}</h5>
                </div>
                <div className="bages">
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }} >
                        <div className="d-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left-circle"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                />
                            </svg>
                            <p className="p-2" onClick={() => { navigate(`/home`); }}>
                                <Link className="nav-link" aria-current="page" to="/home">{t('linksPages.home')}</Link>
                            </p>
                        </div>
                        <div className="d-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left-circle"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                />
                            </svg>
                            <p className="p-2" onClick={() => { navigate(`/about`); }}><Link className="nav-link" to="/about">{t('linksPages.aboutUs')}</Link></p>
                        </div>
                        <div className="d-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left-circle"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                />
                            </svg>
                            <p className="p-2" onClick={() => { navigate(`/service`); }}><Link className="nav-link" to="/service">{t('linksPages.service')}</Link></p>
                        </div>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around' }} >

                        <div className="d-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left-circle"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                />
                            </svg>
                            <p className="p-2" onClick={() => { navigate(`/course`); }}>
                                <Link className="nav-link" to="/course">{t('linksPages.sessions')}</Link></p>
                        </div>
                        <div className="d-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left-circle"
                                viewBox="0 0 16 16">
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                />
                            </svg>
                            <p className="p-2" style={{ maxWidth: "120px" }} onClick={() => { navigate(`/consultations`); }}>
                                <Link className="nav-link" to="/consultations">{t('linksPages.consulting')}</Link>
                            </p>
                        </div>
                        <div className="d-flex">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-arrow-left-circle"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                                />
                            </svg>
                            <p className="p-2" onClick={() => { navigate(`/contact`); }}>
                                <Link className="nav-link" to="/contact">{t('linksPages.contact')}</Link></p>
                        </div>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default PagesFoteer