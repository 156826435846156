import { Box, Button, CardMedia } from '@mui/material'
import React, { useEffect, useState } from 'react'
// import ServiceCard from '../components/ServiceCard'
import Landing from '../components/Landing/Landing'
// import serviceBg from './../assets/الخدمات.png'
// import desDev from "./../assets/CardService/accounting.svg"
import { useGetServicesQuery } from "./../state/apiService"
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import service from './../assets/AllImages/11.jpg'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function Service() {
  const navigate = useNavigate();
  const { t } = useTranslation()
  const { data, isServiseLoading } = useGetServicesQuery({ page: 1, limit: 9999999 });

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if (data && !isServiseLoading) {
      setTableData(data?.servise)
    }
  }, [data, tableData, isServiseLoading])
  let lng = i18next.language

  return (

    <>
      <Landing title={t('linksPages.service')} bg={service} />
      <div className="cours">
        <div className="container">
          <div className="row">
            {
              tableData?.map(serv =>
                <div className="col-md-3 col-xs-12" key={serv._id} >
                  <Box className="box" sx={{ justifyContent: { md: 'flex-start', xs: 'center' } }} >
                    <div className="card" style={{ width: "21rem", margin: '0', marginTop: 20 }}>
                      <CardMedia style={{ height: '200px' }} component={'img'} src={serv?.imageUrl} alt={serv?.title.en} />
                      <div className="card-body">
                        <p className="card-text">
                          <button style={{ color: '#fff' }}
                            onClick={() => navigate('/contact')} className="btn rounded-pill bg">{t('courses.registerNow')}
                          </button>
                        </p>
                        <div className="like d-flex">
                          <p className="fw-bold">{serv?.title[lng]}</p>
                        </div>
                        <Box sx={{ textAlign: 'end' }} >
                          <Button variant="contained" style={{ background: 'linear-gradient(to right bottom, #B0853F, #926412)', color: "#FFF", gap: 16 }}
                            endIcon={<ArrowBackIcon />} onClick={() => navigate(`/service/${serv?._id}`)}>
                            {t('seeMore')}
                          </Button>
                        </Box>
                        <hr />
                      </div>
                    </div>
                  </Box>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </>
  )
}

export default Service