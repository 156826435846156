import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import translateImg from "./../assets/translet.png"
import en from "./../assets/UK-Flag.webp"
import ar from "./../assets/png-transparent-flag-of-the-united-arab-emirates-flag-day-flag-of-japan-uae-flag-angle-flag-text-thumbnail.png"
import i18next from 'i18next';
const lngs = {
  en: { nativeName: <img src={en} alt='' style={{ width: '50px' }} /> },
  ar: { nativeName: <img src={ar} alt='' style={{ width: '50px' }} /> }
};
export default function MyTrans() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { i18n } = useTranslation()
  let lng = i18next.language
  const imagesPath = lng === 'en' ? en : ar
  return (
    <div>
      <Box>

        <Avatar
          sx={{ width: 30, height: 30, cursor: "pointer", backgroundColor: 'transparent' }}
          src={imagesPath}
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {Object.keys(lngs).map((lng) => (
            <MenuItem key={lng}
              style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', zIndex: 99999999999999999999999999999999999999999999999999999, color: "#000" }}
              type="submit"
              value={lng}
              onClick={() => { i18n.changeLanguage(lng); handleClose() }}>{lngs[lng].nativeName}</MenuItem>
          ))}
        </Menu>
      </Box>
    </div>
  );
}
