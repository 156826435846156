import React from 'react'
import Title from '../components/homePage/Title'
import './Home.css'
import CardTeam from '../components/homePage/CardTeam'
import bgDoted from './../assets/CardService/nav.png'
import imgBoss from './../assets/Home/WhatsApp Image 2023-03-04 at 2.38.34 PM.png'
import imgLedr from './../assets/Home/leader.jpg'
import Landing from '../components/homePage/Landing'
import CardInform from '../components/homePage/CardInform'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'
import Send from '@mui/icons-material/Send';
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// import Landing from '../components/Landing/Landing'

function Home() {
  const { t } = useTranslation()
  const navigate = useNavigate();
  return (
    <>
      <Box className="homebg" style={{ position: 'relative' }}>
        <Typography className="over"></Typography>
        <Landing />
      </Box>
      <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" } }} className="d-flex homeV">
        <img src={bgDoted} alt="" className='imgDoted' />
        <CardTeam myTitle={<Title title={t('chairmanMessage')} />}
          descHome={t('descChMessage')}
          descComp={<Button variant="contained"
            style={{ background: 'linear-gradient(to right bottom, #B0853F, #926412)', color: "#FFF", gap: 16 }} endIcon={<ArrowBackIcon />} onClick={() => navigate('/ChairmansMessage')}>
            {t('seeMore')}
          </Button>}
          imgCard={imgBoss} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: { md: "row-reverse", xs: "column-reverse" } }}
        className="d-flex homeV">
        <img src={bgDoted} alt="" className='imgDoted' />
        <Box className="mecard">
          <CardTeam myTitle={<Title title={t('directorMessage')} />}
            descHome={t('descDirector')}
            descComp={<Button variant="contained" style={{ background: 'linear-gradient(to right bottom, #B0853F, #926412)', color: "#FFF", gap: 16 }}
              endIcon={<ArrowBackIcon />} onClick={() => navigate('/DirectorWriter')}>
              {t('seeMore')}
            </Button>}
            imgCard={imgLedr}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" } }} className="d-flex homeV">
        <img src={bgDoted} alt="" className='imgDoted' />
        <CardInform />
      </Box>
    </>
  )
}

export default Home