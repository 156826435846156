import React from 'react'
import logo from "./../../assets/logo.png"
const LogoFoot = () => {
    return (
        <>
            <div>
                <img src={logo} alt="" className="img-fluid p-2" />
                {/* <p className="p-2" style={{ fontWeight: '600', color: '#B38840' }} >
                    {t('descFooter')}
                </p> */}
            </div>
        </>
    )
}

export default LogoFoot