import { Box, Typography } from '@mui/material'
import React from 'react'
import './FormSignIn.css'
import { useTranslation } from 'react-i18next';
import AuthSotial from './AuthSothial/AuthSotial';
import { Link } from 'react-router-dom';
import FormLogic from './FormLogic/FormLogic';

function FormSignIn() {

    const { t } = useTranslation()

    return (
        <>
            <div className="form_sign_up text-center d-inline-flex p-3">
                <div className="container">
                    <div className="left_box">
                        <div className="d-flex flex-column align-items-center">
                            <Typography variant='h3' mb={2} sx={{ color: "#B38840" }} >
                                {t('linksPages.signIn')}
                            </Typography>
                            <FormLogic />
                            {/* <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: 3,
                                    width: { md: "80%", xs: "100%" },
                                }}
                                alignItems={"center"}
                                justifyContent={"center"}
                                mt={2}>
                                <Typography sx={{ borderBottom: "2px solid #707070", width: "40%" }}></Typography>
                                <Typography sx={{ fontWeight: '500' }}>أو</Typography>
                                <Typography sx={{ borderBottom: "2px solid #707070", width: "40%" }}></Typography>
                            </Box>
                            <AuthSotial /> */}
                            <Typography mt={3}
                                sx={{ fontWeight: "bold" }} >
                                {t('auth.dAccount')}?
                                <Typography variant="body">
                                    <Link to="/signUp"
                                        style={{ color: "#F5D593", fontWeight: "bold" }}
                                        className='link-offset-2 link-underline link-underline-opacity-0'>
                                        {t('auth.account')}
                                    </Link>
                                </Typography>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormSignIn