import React, { useEffect, useState } from 'react'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { useGetServicesIdQuery } from '../state/apiService';


const NestService = () => {
    let { id } = useParams()
    const { data, isLoading } = useGetServicesIdQuery(id)
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data?.servise && !isLoading) {
            setTableData(data?.servise)
        }
    }, [data, tableData, isLoading])
    const navigate = useNavigate();

    let lng = i18next.language

    const { t } = useTranslation()
    return (
        <>
            <Box>
                <Box className='container-fluid' sx={{ width: { md: '95%', xs: '100%' }, margin: 'auto' }}>
                    <Box className="row">
                        {
                            <Box className="col-md-12" key={tableData?._id} >
                                <Box className="card" style={{ width: { md: "100%", xs: '90%' }, margin: { md: '5px', xs: 0 }, padding: { md: '0 2%', xs: '0 5%' } }}>
                                    <CardMedia component={'img'} style={{ height: '80vh' }} src={tableData?.imageUrl} alt="..." />
                                    <Box className="card-body" sx={{ padding: { md: '2%', xs: '15px' } }}>
                                        <div className="like d-flex">
                                            <p className="fw-bold">{lng === "ar" ? tableData?.title?.ar : tableData?.title?.en}</p>
                                        </div>
                                        <Typography>
                                            <div className="task-des" dangerouslySetInnerHTML={{
                                                __html: lng === "ar" ? tableData?.description?.ar : tableData?.description?.en
                                            }}></div>
                                            <Box sx={{ textAlign: 'end', margin: '0 0 0 5%' }} >
                                                <Button variant="contained" style={{ background: 'linear-gradient(to right bottom, #B0853F, #926412)', color: "#FFF", gap: 14 }}
                                                    endIcon={<ArrowBackIcon />} onClick={() => navigate(`/service`)}>
                                                    {t('BackService')}
                                                </Button>
                                            </Box>
                                        </Typography>
                                        <hr />
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default NestService