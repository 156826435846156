import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const FoteerMedia = () => {
    const { t } = useTranslation()

    return (
        <>
            <div>
                <div className="box">
                    <h5 className="mb-2" style={{ fontWeight: '600', color: '#B38840' }}>{t('forContact')}</h5>
                </div>
                <div className="media">
                    <div className="d-flex arrow">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-circle"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                            />
                        </svg>
                        <p style={{ margin: 0 }} className="p-2">{t('phoneNumber')}</p>
                        <p style={{ margin: 0 }} className="p-2 number">00971556003491</p>
                    </div>
                    <div className="d-flex arrow">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-circle"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                            />
                        </svg>
                        <p style={{ margin: 0 }} className="p-2"> {t('email')} </p>
                        <p style={{ margin: 0 }} className="p-2 number"> <Link to="mailto:dg@alriyada-alebdaa.com">dg@alriyada-alebdaa.com</Link> </p>
                    </div>
                    <div className="d-flex arrow end">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-left-circle"
                            viewBox="0 0 16 16"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"
                            />
                        </svg>
                        <p style={{ margin: 0 }} className="p-2"> {t('address') + ":"} </p>
                        <Typography sx={{ p: { md: '0.5rem', xs: '0.5rem 0' }, fontWeight: { md: 'bold', xs: 'inherit' } }} style={{ margin: 0 }} className="bu">
                            {t('location')}
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FoteerMedia