import React from 'react'
import course2 from './../../assets/courseSvg/co2.svg'
import course3 from './../../assets/courseSvg/co3.svg'
import course4 from './../../assets/courseSvg/co4.svg'
const FooterCardCourse = () => {
    return (
        <div className="interaction d-flex">
            <div className="star d-flex">
                <p className="fw-bold">2.0k</p>
                <img src={course2} alt="test" />
            </div>
            <div className="star d-flex">
                <p className="fw-bold">9.3k</p>
                <img src={course3} alt="test" />
            </div>
            <div className="star d-flex">
                <p className="fw-bold">2.0k</p>
                <img src={course4} alt="test" />
            </div>
        </div>
    )
}

export default FooterCardCourse