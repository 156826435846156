import { Typography } from '@mui/material'
import React from 'react'
import './FormSignUp.css'
import Person from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom"
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuthContext } from '../../../auth/useAuthContext';
import { enqueueSnackbar } from "notistack";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import haby from './../../../assets/Auth/سعيد.png'
import unHaby from './../../../assets/Auth/غير سعيد.png'
function FormSignUp() {
    const MySwal = withReactContent(Swal)
    const navigate = useNavigate();
    const { register } = useAuthContext();

    const RegisterSchema = Yup.object().shape({
        name: Yup.string().required('First name required'),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        password: Yup.string().required('Password is required'),
        phone: Yup.string().required('phone is required'),
    });

    const defaultValues = {
        name: '',
        phone: '',
        email: '',
        password: '',
        role: 'user'
    };

    const methods = useForm({
        resolver: yupResolver(RegisterSchema),
        defaultValues,
    });
    const {
        reset,
        setError,
        handleSubmit,
        // watch,
        // getValues,
        setValue,
        formState: { isSubmitting, isSubmitSuccessful },
    } = methods;

    //testing :D
    // console.log('errors', errors)


    const onSubmit = async (data) => {
        // console.log("🚀 ~ file: AuthRegisterForm.js:51 ~ onSubmit ~ data:", data)
        //console.log('data', data)
        try {
            if (register) {

                // Check the password length
                if (data.password.length < 8) {
                    // show error
                    const errorMessage = 'كلمة المرور يجب أن تتكون من 8 أحرف على الأقل';
                    enqueueSnackbar(errorMessage, { variant: 'error' });

                    //Reload the page after 1s if the user entered uncorrect password 
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);

                    return;
                }

                await register(data.email, data.password, data.name, data.phone);

                //Give the user notify message
                const successMessage = `تم انشاء الحساب بنجاح سيد ${data.name}`;
                enqueueSnackbar(successMessage, { variant: 'success' });
                navigate('/home');
                MySwal.fire({
                    title: 'Are You Happy',
                    showDenyButton: true,
                    // showCancelButton: true,
                    confirmButtonText: <img src={haby} style={{ width: '50px', height: '50px' }} alt='test' />,
                    denyButtonText: <img src={unHaby} style={{ width: '50px', height: '50px' }} alt='test' />,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        MySwal.fire('Thank you!', '', '#FFF')
                    } else if (result.isDenied) {
                        MySwal.fire('Thank you!', '', '#FFF')
                    }
                })
            }
        } catch (error) {
            console.error(error);
            reset();
            enqueueSnackbar(error.message, { variant: 'error' });

            setError('afterSubmit', {
                ...error,
                message: error.message || error,
            });
        }
    };

    const { t } = useTranslation()
    return (
        <>
            {/* The main container for the sign-up form */}
            <div className="form_sign_up text-center d-inline-flex p-3">
                <div className="container">
                    <div className="left_box">
                        <div className="d-flex flex-column align-items-center">
                            <Typography variant='h3' mb={2} sx={{ color: "#B38840" }} >
                                {t('createAccount')}
                            </Typography>

                            <form gap={20} onSubmit={handleSubmit(onSubmit)} className='my_form'>

                                <div className="input-group mb-3" style={{ marginRight: '0', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <span className="input-group-text" id="basic-addon1"><Person /></span>
                                    <input
                                        name="name"
                                        placeholder='Full Name'
                                        onChange={e => setValue('name', e.target.value)}
                                        style={{ textAlign: 'center', verticalAlign: 'middle', height: '40px', border: '1px solid', width: '100%' }}
                                    />

                                </div>
                                <div className="input-group mb-3" style={{ marginRight: '0', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <span className="input-group-text" id="basic-addon1"><EmailIcon /></span>
                                    <input

                                        name="email"
                                        type="email"
                                        placeholder='Email@example.com'
                                        onChange={e => setValue('email', e.target.value)}
                                        style={{ textAlign: 'center', verticalAlign: 'middle', height: '40px', border: '1px solid', width: '100%' }}
                                    />

                                </div>

                                <div className="input-group mb-3" style={{ marginRight: '0', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <span className="input-group-text" id="basic-addon1"><HttpsOutlinedIcon /></span>
                                    <input

                                        name="password"
                                        placeholder='********'
                                        onChange={e => setValue('password', e.target.value)}
                                        style={{ textAlign: 'center', verticalAlign: 'middle', height: '40px', border: '1px solid', width: '100%' }}
                                    />
                                </div>
                                <div className="input-group mb-3" style={{ marginRight: '0', justifyContent: 'center', alignItems: 'center', flexWrap: 'nowrap' }}>
                                    <span className="input-group-text" id="basic-addon1"><LocalPhoneIcon /></span>
                                    <input

                                        name="phone"
                                        placeholder='******** 971+'
                                        onChange={e => setValue('phone', e.target.value)}
                                        style={{ textAlign: 'center', verticalAlign: 'middle', height: '40px', border: '1px solid', width: '100%' }}
                                    />
                                </div>


                                {/* <div className="input-group mb-3">
                                    <span className="input-group-text" id="basic-addon1"><HttpsOutlinedIcon /></span>
                                    <input
                                        type="password"
                                        name='password'
                                        className="form-control"
                                        id="password"
                                        placeholder="*********"
                                        {...register("password")}
                                    />
                                </div> */}


                                <Typography variant='p' >
                                    {t('auth.means')}
                                    <Typography variant='standard' sx={{ color: "#B38840" }}>
                                        {t('auth.privacyPolicy')}
                                    </Typography>
                                    {t('auth.AND')}
                                    <Typography variant='standard' sx={{ color: "#B38840" }} >
                                        {t('auth.termsOfUse')}
                                    </Typography>
                                </Typography>


                                {/* Submit button */}
                                <Typography sx={{ width: "100%" }} className="my_btn" mt={3}>


                                    {/* <Button
                                        fullWidth
                                        color="inherit"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting || isSubmitSuccessful}
                                        sx={{
                                            bgcolor: 'text.primary',
                                            color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                            '&:hover': {
                                                bgcolor: 'text.primary',
                                                color: (theme) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
                                            },
                                        }}
                                    >
                                        Create account
                                    </Button> */}


                                    <button
                                        type='submit'
                                        loading={isSubmitting || isSubmitSuccessful}
                                        className="bg btn mt-3 text-center"
                                        style={{ width: "100%", fontWeight: "bold" }}>
                                        {t('courses.registerNow')}
                                    </button>
                                </Typography>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FormSignUp








