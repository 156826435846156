import { Box } from '@mui/material'
import React from 'react'
import Title from '../components/homePage/Title'
import CardTeam from '../components/homePage/CardTeam'
import MyList from '../components/homePage/MyList'
import bgDoted from './../assets/CardService/nav.png'

import imgLedr from './../assets/Home/leader.jpg'

import { useTranslation } from 'react-i18next'


function DirectorWriter() {
    const { t } = useTranslation()
    return (
        <div><Box sx={{ display: "flex", flexDirection: { md: "row-reverse", xs: "column-reverse" } }} className="d-flex homeV">
            <img src={bgDoted} alt="" className='imgDoted' />
            <Box className="mecard">
                <CardTeam myTitle={<Title title={t('directorMessage')} />}
                    descHome={t('descDirector')}
                    descComp={
                        <MyList
                            one={t('descDirectorCompList1')}
                            tow={t('descDirectorCompList2')}
                            three={t('descDirectorCompList3')}
                            four={t('descDirectorCompList4')}
                            five={t('descDirectorCompList5')}
                            six={t('descDirectorCompList6')}
                            nameWorking={t('card.nameWorking')}
                            name={t('card.name')}
                        />}
                    imgCard={imgLedr}
                />
            </Box>
        </Box></div>
    )
}

export default DirectorWriter