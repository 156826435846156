import React from 'react'
// import Navbar from '../components/Navbar'
import AuthJwtPass from '../components/Authentication/JwtPassword/ForgitPassword/AuthJwtPass'
import NavBar from '../NavBar'

function JWTForgetPass() {
  return (
    <>
      {/* <Navbar /> */}
      <NavBar />
      <AuthJwtPass />
    </>
  )
}

export default JWTForgetPass