import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import theme from "./util/theme";
import { Provider as ReduxProvider } from 'react-redux';
// redux
import { store } from './redux/store';
// import { SnackbarProvider } from "notistack";
import reportWebVitals from './reportWebVitals';
// import './i18n'
import './locales/i18n';
import { AuthProvider } from './auth/JwtContext';
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
        <React.StrictMode>
                <AuthProvider>
                        <ReduxProvider store={store}>
                                <ThemeProvider theme={theme}>
                                        <SnackbarProvider
                                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                                                <App />
                                        </SnackbarProvider>
                                </ThemeProvider>
                        </ReduxProvider>
                </AuthProvider>
        </React.StrictMode>
        // <React.StrictMode>
        //   <React.Suspense fallback="loading">
        //     <App />
        //   </React.Suspense>
        // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
