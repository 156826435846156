import React from 'react'
import Landing from './../components/Landing/Landing'
import ContactUs from './../components/Contact/ContactUs'
import { useTranslation } from 'react-i18next'
import contact from './../assets/AllImages/3.jpg'

function Contact() {
  const { t } = useTranslation()
  return (
    <>
      <Landing title={t('linksPages.contact')} bg={contact} />
      <ContactUs />
    </>

  )
}

export default Contact