import React from 'react'
import Landing from '../components/Landing/Landing'
import AboutUs from '../components/AboutUs/AboutUs'
import { useTranslation } from 'react-i18next'
import about from './../assets/AllImages/25.jpg'
function About() {
    const { t } = useTranslation()
    return (
        <>
            <Landing bg={about} title={t('aboutUs.title')} />
            <AboutUs />
        </>
    )
}

export default About