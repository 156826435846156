import React from 'react'
import RightBoxAuth from './../global/RightBoxAuth'
import FormSignIn from './FormSignIn'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

function AuthSignIn() {
  const { t } = useTranslation()
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", }} className="d-flex align-items-center">
        <RightBoxAuth title={t('linksPages.signIn')} />
        <FormSignIn />
      </Box>
    </>
  )
}

export default AuthSignIn