import React from 'react'
import RightBoxAuth from './../../global/RightBoxAuth'
import JWTForgetPassComp from './JWTForgetPassComp'
import { useTranslation } from 'react-i18next'

function AuthJwtPass() {
  const { t } = useTranslation()

  return (
    <>
      <div className="d-flex align-items-center">
        <RightBoxAuth title={t('auth.forgotPassword')} />
        <JWTForgetPassComp />
      </div>
    </>
  )
}

export default AuthJwtPass