import React from 'react'
// import Navbar from '../components/Navbar'
import AuthResetPass from '../components/Authentication/JwtPassword/ResetPassword/AuthResetPass'
import NavBar from "./../NavBar";

function ResetPassword() {
  return (
    <>
      {/* <Navbar /> */}
      <NavBar />
      <AuthResetPass />
    </>
  )
}

export default ResetPassword