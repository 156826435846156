import React from 'react'
import "./Landing.css"
import background from "./../../assets/الريادة والابداع .png"
import { Box, CardMedia, Container, Typography } from '@mui/material'

function Landing({ title, bg }) {
  return (
    <>
      {/* <!-- Start landing --> */}
      <Box>
        <CardMedia src={bg} component={'img'} sx={{ height: '70vh' }} />
      </Box>
      {/* <Box className="landing"></Box> */}
      {/* <!-- END landing --> */}
      {/* <!-- Start Title --> */}
      <Box sx={{ mt: 0 }} >
        <Container>
          <Box className="text-center">
            <img
              src={background}
              alt=""
              className="img-fluid text-center"
            />
            <Box sx={{ mt: -4 }}>
              <Typography variant='h3' sx={{ fontWeight: 'bold', position: 'relative', width: 'fit-content', margin: 'auto' }} className='title' >{title}</Typography>
            </Box>
          </Box>
        </Container>
      </Box >
      {/* <!-- END Title --> */}
    </>
  )
}

export default Landing