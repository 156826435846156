import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Avatar, Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../auth/useAuthContext';
import { Link, useNavigate } from 'react-router-dom';

function AvatarAccount() {
    const accessToken = localStorage.getItem('accessToken');
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { user, logout } = useAuthContext();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            logout();
            navigate('/signIn');
            // handleClosePopover();
        } catch (error) {
            console.error(error);
            //   enqueueSnackbar('Unable to logout!', { variant: 'error' });
        }
    };
    return (
        <>
            {
                accessToken ? <Box>

                    <Avatar
                        sx={{ cursor: "pointer" }}
                        src={user?.photo}
                        alt={user?.name}
                        name={user?.name}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    />


                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <Box sx={{ my: 1.5, px: 2.5 }}>
                            <Typography variant="subtitle2" noWrap>
                                {user?.name}
                            </Typography>

                            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                {user?.email}
                            </Typography>
                        </Box>

                        <Divider sx={{ borderStyle: 'dashed' }} />

                        <Divider sx={{ borderStyle: 'dashed' }} />

                        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Box>
                    :
                    <Typography className="link d-flex" gap={1} >
                        <Link to="/signUp" className="btn bg">{t('linksPages.signUp')}</Link>
                        <Link to="/signIn" className="btn nbg bg">{t('linksPages.signIn')}</Link>
                    </Typography>}
        </>
    )
}

export default AvatarAccount