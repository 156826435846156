import { Box, Typography } from '@mui/material'
import React from 'react'

function Title({ title, color }) {
  return (
    <div className="my_title">
      <Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          columnGap={2}
        >
          <Typography variant='span' sx={{ borderRight: "50px solid #B38840", mt: "11px", height: "4px" }}></Typography>
          <Typography variant='h5' sx={{ fontWeight: "bold", color: { color } ? { color } : "#FFF" }}>{title}</Typography>
        </Box>
      </Box>
    </div>
  )
}

export default Title