import { Box, Checkbox, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';

import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

import { useForm } from "react-hook-form";

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

import BookmarkIcon from '@mui/icons-material/Bookmark';

import { useNavigate } from 'react-router-dom';

import { useAuthContext } from './../../../../auth/useAuthContext';

import { yupResolver } from '@hookform/resolvers/yup';
import haby from './../../../../assets/Auth/سعيد.png'
import unHaby from './../../../../assets/Auth/غير سعيد.png'
import * as Yup from 'yup';
// import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { enqueueSnackbar } from "notistack";
// import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
function FormLogic() {
    const navigate = useNavigate();
    const MySwal = withReactContent(Swal)
    const { login } = useAuthContext();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        password: Yup.string().required('Password is required'),
    });
    const { register, handleSubmit, reset } = useForm({
        resolver: yupResolver(LoginSchema),
    });
    const onSubmit = async (data) => {
        try {
            await login(data.email, data.password);
            enqueueSnackbar("تم تسجيل الدخول", { variant: 'success' })
            navigate('/home')
            MySwal.fire({
                title: 'Are You Happy',
                showDenyButton: true,
                // showCancelButton: true,
                confirmButtonText: <img src={haby} style={{ width: '50px', height: '50px' }} alt='test' />,
                denyButtonText: <img src={unHaby} style={{ width: '50px', height: '50px' }} alt='test' />,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    MySwal.fire('Thank you!', '', '#FFF')
                } else if (result.isDenied) {
                    MySwal.fire('Thank you!', '', '#FFF')
                }
            })
        } catch (error) {
            console.error(error);
            enqueueSnackbar('الحساب غير موجود', { variant: 'error' });
            reset();
        }
    };

    const { t } = useTranslation()
    return (
        <>
            <Box component="form" gap={20} onSubmit={handleSubmit(onSubmit)} className='my_form'>
                <div className="input-group mb-3">
                    <input
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="name@example.com"
                        {...register("email", { required: true })}
                    />
                    <span className="input-group-text" id="basic-addon1"><EmailIcon /></span>
                </div>

                <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1"><HttpsOutlinedIcon /></span>
                    <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="*********"
                        {...register("password", { required: true })}
                    />
                </div>

                <Typography sx={{ width: "100%" }} className="my_btn" mt={2} >
                    <button
                        type="submit"
                        className="bg btn mt-1 text-center"
                        style={{ width: "100%" }}>
                        {t('courses.registerNow')}
                    </button>
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }} justifyContent="space-between" mt={3} >
                    <Typography>
                        <span>{t('auth.reminder')}</span>
                        <Checkbox
                            aria-label={t('auth.reminder')}
                            icon={<BookmarkBorderIcon />}
                            checkedIcon={<BookmarkIcon />}
                        />
                    </Typography>
                    {/* <Typography className={"text-danger"}>
                        <Link to="/forgetPassword"
                            className='link-offset-2 link-underline link-underline-opacity-0 text-danger'>
                            {t('auth.forgotPassword')} ؟</Link>
                    </Typography> */}
                </Box>
            </Box>
        </>
    )
}

export default FormLogic