import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import logo from "./../assets/logo.png"
import { useTranslation } from 'react-i18next';
import Container from "@mui/material/Container";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import './myStyle.css'
import MyTrans from './MyTrans'
import AvatarAccount from "./AvatarAccount";
import i18next from "i18next";

function NavBar() {
    const { t } = useTranslation()
    const [drawer, setDrawer] = useState(false);
    let lng = i18next.language
    return (
        <>
            <AppBar className="myNav" position="relative" elevation={0} style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", zIndex: 99, direction: 'rtl', backgroundColor: '#232a6d' }}>
                <Container>
                    <Toolbar sx={{ alignItems: "baseline" }} disableGutters>
                        <Link
                            to="/"
                            style={{
                                color: "#fff",
                                textDecoration: "none",
                            }}
                        >
                            <img src={logo} alt="" style={{ marginRight: "-10px" }} className="img-fluid" />
                        </Link>

                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 1,
                                display: { md: "flex", xs: "none" },

                                alignItems: "center",
                            }}
                            style={lng === 'ar' ? { direction: 'rtl', justifyContent: "space-around", } : { direction: 'ltr', justifyContent: "space-around", }}
                        >
                            <Box sx={{ display: 'flex', gap: 4 }} >
                                <Link to="/home" className="nav-link" style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }} aria-current="page" >{t('linksPages.home')}</Link>

                                <Link to="/about" className="nav-link" style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }} >{t('linksPages.aboutUs')}</Link>

                                <Link to="/service" className="nav-link" style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }} aria-current="page" >{t('linksPages.service')}</Link>


                                <Link to="/course" className="nav-link" style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }} >{t('linksPages.sessions')}</Link>

                                <Link to="/consultations" className="nav-link" style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }} >{t('linksPages.consulting')}</Link>

                                <Link to="/contact" className="nav-link" style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    fontSize: 15,
                                    fontWeight: "bold",
                                }} >{t('linksPages.contact')}</Link>

                            </Box>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }} >
                                <MyTrans />
                                <AvatarAccount />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { md: "none", xs: "flex" },
                                justifyContent: "end",
                                color: '#B38840'
                            }}>
                            <IconButton onClick={() => setDrawer(true)} sx={{ color: '#B38840' }} >
                                <MenuIcon sx={{ color: '#B38840' }} />
                            </IconButton>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
