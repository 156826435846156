import React, { useState } from 'react'
import { Box, Button, CardActions, CardContent, Collapse, Typography } from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

function ListCardInform({ one, tow, three, four, five, six, seven, eight }) {
    const [isExpanded] = useState(false);
    const { t } = useTranslation()
    const navigate = useNavigate();

    return (
        <ol style={{ fontWeight: 'bold' }} >
            <li>{one}</li>
            <li>{tow}</li>
            <li>{three}</li>
            <li>{four}</li>
            <Box sx={{ p: 4, fontWeight: 'bold' }}>
                <CardActions>
                    <Button
                        variant="contained"
                        style={{ background: 'linear-gradient(to right bottom, #B0853F, #926412)', color: "#FFF", gap: 16 }}
                        endIcon={<ArrowBackIcon />} onClick={() => navigate('/Vision')}>
                        {t('seeMore')}
                    </Button>
                </CardActions>
                <Collapse
                    in={isExpanded}
                    timeout="auto"
                    unmountOnExit
                >
                    <CardContent>
                        <Typography variant='ol' sx={{ fontWeight: 'bold' }} >
                            <li>{five}</li>
                            <li>{six}</li>
                            <li>{seven}</li>
                            <li>{eight}</li>
                        </Typography>
                    </CardContent>
                </Collapse>
            </Box>
        </ol>
    )
}

export default ListCardInform