import React from 'react'
import { Box, Container, Typography } from '@mui/material'
import Title from './Title'
import ButtonHome from './ButtonHome'
import { useTranslation } from 'react-i18next'
// import SendIcon from '@mui/icons-material/Send';

function Landing() {
  const { t } = useTranslation()
  return (
    <Box sx={{ display: 'flex', flexDirection: "column", alignItems: "flex-start" }}>
      <Container>
        <Box sx={{ width: { md: "100%", xs: "100%" } }} >
          <Title title={t('home.mainTitle')} color={'#e7a934'} />
          <Typography variant="h5" sx={{ px: 2, color: '#e7a934', fontSize: { md: '1.5rem', xs: '1.25rem' } }}>
            {t('home.mainDesc')}
          </Typography>
          <Typography variant="h5" sx={{ px: 2, display: "inline-block", width: "100%", fontSize: { md: '1.5rem', xs: '1.25rem' } }}>
            {t('home.descLanding')}
          </Typography>
          <Box sx={{ display: { md: 'block', xs: 'none' } }} >
            <ButtonHome desc={t('home.descLandingComp')} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Landing