import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './ListHome.css'
function ListHome({ title, nestTitle }) {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 0, fontWeight: 'bold' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                <ListItemButton onClick={handleClick} sx={{ fontWeight: 'bold' }}>
                    <ListItemText component="h5" primary={title} sx={{ fontWeight: 'bold' }} />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ fontWeight: 'bold' }}>
                    <List component="div" disablePadding sx={{ fontWeight: 'bold' }} >
                        <ListItemButton sx={{ pl: 4, fontWeight: 'bold' }}>
                            <ListItemText primary={nestTitle} sx={{ fontWeight: 'bold' }} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </>
    );
}

export default ListHome