import React, { useEffect, useState } from 'react'
import { useGetCourseIdQuery } from '../state/ApiCource';
import { useLocales } from '../locales';
import i18next from 'i18next'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, Button, CardMedia, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../auth/useAuthContext';

const NesrCourse = () => {
    let { id } = useParams()
    const { data, isLoading } = useGetCourseIdQuery(id)
    const [tableData, setTableData] = useState([]);
    let [countData, setCountData] = useState(0);
    const { user } = useAuthContext();
    useEffect(() => {
        console.log(tableData); // سترى القيمة المحدثة هنا
    }, [tableData]);

    // const handleRegister = () => {
    //     const newCount = countData + 1;
    //     setCountData(newCount);
    //     if (newCount >= 20) {
    //         setTableData({ ...tableData, active: "false" });
    //     }
    //     localStorage.setItem(`userEmail_${id}`, user?.email);

    // };
    // console.log("🚀 ~ file: NesrCourse.jsx:37 ~ handleButtonClick ~ saveEmail:", localStorage.getItem(`userEmail_${id}`))
    // const handleButtonClick = () => {
    //     // استبدل 'token' بالمتغير الذي تستخدمه لتخزين الرمز المميز
    //     localStorage.getItem(`userEmail_${id}`);
    //     if (user?.email) {
    //         handleRegister();
    //     } else {
    //         navigate('/signIn');
    //     }
    // };
    useEffect(() => {
        if (data?.course && !isLoading) {
            const savedActiveState = localStorage.getItem(`courseActiveState_${id}`);
            const savedCount = localStorage.getItem(`courseCount_${id}`);

            // استعادة رمز المستخدم من التخزين المحلي
            const saveEmail = localStorage.getItem(user?.email);
            if (savedActiveState !== null && savedCount !== null && saveEmail === user?.email) {
                setTableData({ ...data.course, active: JSON.parse(savedActiveState) });
                setCountData(JSON.parse(savedCount));
            } else {
                setTableData(data.course);
            }
        }
    }, [data, isLoading]);

    useEffect(() => {
        localStorage.setItem(`courseCount_${id}`, JSON.stringify(countData));
    }, [countData]);
    useEffect(() => {
        if (tableData.active !== undefined) {
            localStorage.setItem(`courseActiveState_${id}`, JSON.stringify(tableData.active));
        }
    }, [tableData.active]);
    const navigate = useNavigate();

    const { translate } = useLocales();
    let lng = i18next.language
    const { t } = useTranslation()
    return (
        <>
            <Box>
                <Box className='container-fluid' sx={{ width: { md: '95%', xs: '100%' }, margin: 'auto' }} >
                    <Box className="row">
                        {
                            <Box className="col-md-12" key={tableData?._id} >
                                <Box className="card" style={{ width: { md: "100%", xs: '90%' }, margin: { md: '5px', xs: 0 }, padding: { md: '0 2%', xs: '0 5%' } }}>
                                    <CardMedia component={'img'} style={{ height: '80vh' }} src={tableData?.imageUrl} alt="..." />
                                    <Box className="card-body" sx={{ padding: { md: '2%', xs: '15px' } }} >
                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                            <p className="card-text">
                                                <Button onClick={() => { navigate('/contact'); }}
                                                    // disabled={tableData?.active === "false" ? true : false}
                                                    // disabled={true}
                                                    sx={{ backgroundColor: '#B38840', color: '#fff' }} >
                                                    {translate('courses.registerNow')}
                                                </Button>
                                            </p>
                                            <Typography className="salary d-flex"
                                                sx={{ display: 'flex', gap: 3, alignItems: 'center', m: 2 }} >
                                                <Typography style={{
                                                    color: tableData?.active === 'true' ? 'green' : 'red',
                                                    background: '#dddddd70',
                                                    fontWeight: 'bold', padding: '10px 25px', borderRadius: 10
                                                }} >{tableData?.active === 'true' ? t('avi') : t('unAvi')}</Typography>
                                                <p>{tableData?.price} <span>{translate('courses.AED')}</span></p>
                                            </Typography>
                                        </Box>
                                        <div className="like d-flex">
                                            <p className="fw-bold">{lng === "ar" ? tableData?.title?.ar : tableData?.title?.en}</p>
                                        </div>
                                        <p className="time">{translate('courses.courseDuration')}:<span>{tableData?.duration}</span> </p>
                                        <Typography>
                                            <div className="task-des" dangerouslySetInnerHTML={{
                                                __html: lng === 'ar' ? tableData?.description?.ar : tableData?.description?.en
                                            }}></div>
                                            <Box sx={{ textAlign: 'end', margin: '0 0 0 5%' }} >
                                                <Button variant="contained" style={{ background: 'linear-gradient(to right bottom, #B0853F, #926412)', color: "#FFF", gap: 14 }}
                                                    endIcon={<ArrowBackIcon />} onClick={() => navigate(`/course`)}>
                                                    {t('Back')}
                                                </Button>
                                            </Box>
                                        </Typography>
                                        <hr />
                                    </Box>
                                </Box>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default NesrCourse