import React from 'react'
import "./Foteer.css"
import { Box, Container, Grid } from '@mui/material';
import DownFooter from './DownFooter';
import PagesFoteer from './PagesFoteer';
import FoteerMedia from './FoteerMedia';
import Sotial from './Sotial';
import LogoFoot from './LogoFoot';
function Foteer() {

    return (
        <>
            {/* <!-- Start footer --> */}
            {/* <div className="footer">
                <div className="container-fluid">
                    <Box className="row" alignItems={'center'} >
                        <LogoFoot />
                        <PagesFoteer />
                        <Sotial />
                        <FoteerMedia />
                    </Box>
                </div>
            </div> */}
            <Box className="footer">
                <Container>
                    <Grid container spacing={1} alignItems={'center'} >
                        <Grid item md={2} xs={12}><LogoFoot /></Grid>
                        <Grid item md={8} xs={12}><PagesFoteer /></Grid>
                        <Grid item md={2} xs={12}><Sotial /></Grid>
                    </Grid>
                    <Grid container spacing={1} alignItems={'center'} >
                        <Grid item md={1} xs={12}></Grid>
                        <Grid item md={10} xs={12}><FoteerMedia /></Grid>
                        <Grid item md={1} xs={12}></Grid>
                    </Grid>
                </Container>
            </Box>
            <DownFooter />
            {/* <!-- END footer --> */}
        </>
    )
}

export default Foteer