import React from 'react'
import './CardTeam.css'
import IconOne from './../../assets/Home/CardIcon/1000_F_547864264_MjWWHptvkcSuTkajA6XM4m6ro5cvZQBe.png'
import IconTow from './../../assets/Home/CardIcon/bank.png'
import { Typography } from '@mui/material'

function CardTeam({ myTitle, descHome, descComp, imgCard }) {
    return (
        <Typography className="card m-2" style={{ maxWidth: "540px;" }}>
            <Typography className="row g-0">
                <Typography className="col-md-8" sx={{ fontWeight: 'bold' }} >
                    {myTitle}
                    <Typography className="card-body" sx={{ fontWeight: 'bold' }} >
                        <Typography sx={{ p: 2, fontWeight: 'bold' }} className="card-text">{descHome} </Typography>
                        {descComp}
                        <Typography className="d-flex justify-content-evenly m-2">
                            <Typography className="boxIcon">
                                <img src={IconTow} alt="" />
                            </Typography>
                            <Typography className="boxIcon">
                                <img src={IconTow} alt="" />
                            </Typography>
                            <Typography className="boxIcon">
                                <img src={IconOne} alt="" />
                            </Typography>
                        </Typography>
                        {/* <Typography className="d-flex justify-content-evenly m-2">
                            <Typography className="boxText">
                            <p>test text</p>
                            </Typography>
                            <Typography className="boxText">
                            <p>test text</p>
                            </Typography>
                            <Typography className="boxText">
                            <p>test text</p>
                            </Typography>
                        </Typography> */}
                    </Typography>
                </Typography>
                <Typography className="col-md-4">
                    <img src={imgCard} className="img-fluid rounded-start" alt="..." />
                </Typography>
            </Typography>
        </Typography>
    )
}

export default CardTeam