import { Box, Button, CardActions, CardContent, Collapse, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function ButtonHome({ desc, nameWorking, name }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation()
  const [hidden, setHidden] = useState(false);
  return (
    <Box>
      {!hidden &&
        <CardActions>
          <Button
            variant="contained"
            style={{
              background: 'linear-gradient(to right bottom, #B0853F, #926412)',
              color: "#FFF",
              gap: 16
            }} endIcon={<ArrowBackIcon />}
            onClick={() => { setIsExpanded(!isExpanded); setHidden(true) }}>
            {t('seeMore')}
          </Button>
        </CardActions>
      }
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
      >
        <CardContent sx={{ padding: '0 16px' }} >
          <Typography variant="h5">
            {desc}
            <Typography variant="h5" textAlign={"left"}>
              {nameWorking}
              <br />
              {name}
            </Typography>
          </Typography>
        </CardContent>
      </Collapse>
    </Box>
  )
}

export default ButtonHome