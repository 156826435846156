import React, { useEffect, useState } from 'react'
import Landing from '../../components/Landing/Landing'
import { useGetCourseQuery } from "../../state/ApiCource"
import { useLocales } from '../../locales'
import i18next from 'i18next'
import { Box, Button, CardMedia, Typography } from '@mui/material'
import './../../components/Course/CardCourse.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import course from './../../assets/AllImages/3.jpg'
import course1 from './../../assets/courseSvg/co1.svg'
import FooterCardCourse from './FooterCardCourse'


function Consultations() {
    const { data, isLoading } = useGetCourseQuery({ page: 1, limit: 999999 })
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        if (data && !isLoading) {
            setTableData(data?.courses)
        }
    }, [data, tableData, isLoading])

    const { translate } = useLocales();
    let lng = i18next.language
    const { t } = useTranslation()
    const navigate = useNavigate();

    return (
        <>
            <Landing bg={course} title={translate('courses.trainingCourses')} />
            <div className="cours">
                <div className="container">
                    <div className="row">
                        {
                            tableData.map(course =>
                                <div className="col-md-4 col-xs-12" key={course._id} >
                                    <Box className="box" sx={{ justifyContent: { md: 'flex-start', xs: 'center' } }} >
                                        <div className="card" style={{ width: "21rem", margin: '0', marginTop: 20 }}>
                                            <CardMedia style={{ height: '250px' }} component={'img'} src={course.imageUrl} alt="..." />
                                            <div className="card-body">
                                                <p className="card-text">
                                                    <button onClick={() => navigate('/contact')}
                                                        // disabled={course?.active === "false" ? true : false}
                                                        style={{ color: '#FFF' }}
                                                        className="sajel btn rounded-pill bg">
                                                        {translate('courses.registerNow')}
                                                    </button>
                                                </p>
                                                <Typography className="salary d-flex" sx={{ alignItems: 'center' }} >
                                                    <button className="btn rounded-pill"
                                                        style={{
                                                            color: course?.active === 'true' ? 'green' : 'red',
                                                            background: '#dddddd70', fontWeight: 'bold'
                                                        }} >
                                                        {course?.active === 'true' ? t('avi') : t('unAvi')}
                                                    </button>
                                                    <p>{course?.price} <span>{translate('courses.AED')}</span></p>
                                                </Typography>
                                                <div className="like d-flex">
                                                    <p className="fw-bold">{course?.title[lng]}</p>
                                                    <img src={course1} alt="test" />
                                                </div>
                                                <p className="time">{translate('courses.courseDuration')}:
                                                    <span>{course?.duration}</span>
                                                    {course?.duration > 10 ? translate('courses.day') : translate('courses.days')}
                                                </p>
                                                <Box sx={{ textAlign: 'end' }} >
                                                    <Button variant="contained" style={{ background: 'linear-gradient(to right bottom, #B0853F, #926412)', color: "#FFF", gap: 16 }}
                                                        endIcon={<ArrowBackIcon />} onClick={() => navigate(`/course/${course?._id}`)}>
                                                        {t('seeMore')}
                                                    </Button>
                                                </Box>
                                                <hr />
                                                <FooterCardCourse />
                                            </div>
                                        </div>
                                    </Box>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Consultations