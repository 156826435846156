import { Box } from '@mui/material'
import React from 'react'
import CardTeam from '../components/homePage/CardTeam'
import Title from '../components/homePage/Title'
import bgDoted from './../assets/CardService/nav.png'
import { useTranslation } from 'react-i18next'
import imgBoss from './../assets/Home/WhatsApp Image 2023-03-04 at 2.38.34 PM.png'

function ChairmansMessage() {
    const { t } = useTranslation()

    return (
        <div><Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" } }} className="d-flex homeV">
            <img src={bgDoted} alt="" className='imgDoted' />
            <CardTeam myTitle={<Title title={t('chairmanMessage')} />}
                descHome={t('descChMessage')}
                descComp={t('descChMessageComp')}
                imgCard={imgBoss} />
        </Box></div>
    )
}

export default ChairmansMessage