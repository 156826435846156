import React from 'react'
import { Typography } from '@mui/material'
import './../../SignUp/FormSignUp.css'
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import { useForm } from "react-hook-form";
import ResetPass from './../../../../assets/Auth/Reset password-rafiki (2).png'
import "./../ForgitPassword/ForgetPass.css"
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useNewPassMutation } from '../../../../state/ApiAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
function ResetPassComp() {
    const MySwal = withReactContent(Swal)
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    // const { enqueueSnackbar } = useSnackbar();

    // const [showPassword, setShowPassword] = useState(false);

    const VerifyCodeSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
        confirmPassword: Yup.string()
            .required("Confirm password is required")
            .oneOf([Yup.ref("password")], "Passwords must match"),
    });

    const defaultValues = {
        password: "",
        confirmPassword: "",
    };

    const methods = useForm({
        mode: "onChange",
        resolver: yupResolver(VerifyCodeSchema),
        defaultValues,
    });

    // const {
    //     handleSubmit,
    //     formState: { isSubmitting, errors },
    // } = methods;
    const [newPass] = useNewPassMutation();
    console.log({
        token: searchParams.get("token"),
        userId: searchParams.get("id"),
    });
    const onSubmit = async (data) => {
        try {
            await newPass({
                token: searchParams.get("token"),
                userId: searchParams.get("id"),
                password: data.password,
            });
            navigate('/home')

            sessionStorage.removeItem("email-recovery");
            // enqueueSnackbar("Change password success!");
            // navigate(PATH_DASHBOARD.root);
        } catch (error) {
            console.error(error);
        }
    };

    const { t } = useTranslation()
    return (
        <>
            <div className="form_sign_up text-center d-inline-flex p-3">
                <div className="container">
                    <div className="left_box">
                        <div className="d-flex flex-column align-items-center">
                            <img src={ResetPass} alt="" className='img-fluid mt-4' style={{ height: "50vh" }} />
                            <div className="my_box d-flex flex-column align-items-center">
                                <form onSubmit={handleSubmit(onSubmit)} className='my_form'>
                                    <Typography sx={{ display: "block", textAlign: "right", fontWeight: "bold" }} for="password" className="form-label">{t('auth.newPassword')}</Typography>
                                    <div className="input-group mb-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            placeholder="*********"
                                            {...register("password", { required: true })} />
                                        <span className="input-group-text" id="basic-addon1"><HttpsOutlinedIcon /></span>
                                    </div>
                                    <Typography sx={{ display: "block", textAlign: "right", fontWeight: "bold" }} for="resetPassword" className="form-label">{t('auth.confirmNewPassword')}</Typography>
                                    <div className="input-group mb-3">
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="resetPassword"
                                            placeholder="*********"
                                            {...register("password", { required: true })} />
                                        <span className="input-group-text" id="basic-addon1"><HttpsOutlinedIcon /></span>
                                    </div>
                                    {errors.password && <span>This field is required</span>}
                                    <Typography sx={{ width: "100%" }} className="my_btn" mt={2}>
                                        {/* <Link className='link-offset-2 link-underline link-underline-opacity-0' style={{ color: "#000", fontWeight: "bold", }}>    */}
                                        <button
                                            className="bg btn mt-1 text-center"
                                            style={{ width: "100%", fontWeight: "bold", color: "#000" }}
                                            onClick={() => {
                                                MySwal.fire({
                                                    position:
                                                        'center',
                                                    icon: 'success',
                                                    confirmButtonText: 'Save',
                                                    title: t('auth.passwordChanged'),
                                                    text: t('auth.passwordChangedComp')
                                                    // timer: 1500
                                                })
                                                    .then((result) => {
                                                        /* Read more about isConfirmed, isDenied below */
                                                        if (result.isConfirmed) {
                                                            //   navigate('/home')
                                                        } else if (result.isDenied) {
                                                            // navigate('/home')
                                                        }
                                                    })
                                            }
                                            }>
                                            {t('next')}
                                        </button>
                                        {/* </Link> */}
                                    </Typography>
                                    <Typography
                                        mt={3}
                                        sx={{ fontWeight: "bold" }} >
                                        <Link
                                            to="/signIn"
                                            style={{ color: "#000" }}
                                            className='link-offset-2 link-underline link-underline-opacity-0'>

                                            {t('auth.backLogIn')}

                                        </Link>
                                    </Typography>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResetPassComp