import React from 'react'
// import Navbar from '../components/Navbar'
import AuthForgetPass from '../components/Authentication/JwtPassword/ForgitPassword/AuthForgetPass'
import NavBar from '../NavBar'

function ForgetPass() {
  return (
    <>
      <NavBar />
      {/* <Navbar /> */}
      <AuthForgetPass />
    </>
  )
}

export default ForgetPass