import './App.css';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Layout from './view/Layout';
import Contact from './view/Contact';
import About from './view/About';
import Service from './view/Service';
import Consultations from './view/Consultations';
import Course from './view/Course/Course';
import Home from './view/Home';
import SignUp from './view/SignUp';
import SignIn from './view/SignIn';
import ForgetPass from './view/ForgetPass';
import JWTForgetPass from './view/JWTForgetPass';
import ResetPassword from './view/ResetPassword';
import DirectorWriter from './view/DirectorWriter';
import ChairmansMessage from './view/ChairmansMessage';
import Vision from './view/Vision';
import Cookies from 'js-cookie';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollTo from './components/UI/ScrollToTop';
import NesrCourse from './view/NesrCourse';
// import { allLangs } from './locales';
import SotialFooter from './components/glopal/SotialFooter';
import NestService from './view/NestService';

const languages = [
  {
    code: 'ar',
    name: 'عربي',
    country_coode: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'English',
    country_coode: 'gb',
    dir: 'ltr'
  }
]
function App() {

  const currentLanguageCode = Cookies.get('i18next') || 'ar';
  // console.log("🚀 ~ file: App.js:35 ~ App ~ currentLanguageCode:", currentLanguageCode)
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
  // console.log("🚀 ~ file: App.js:41 ~ App ~ currentLanguage:", currentLanguage)
  const { t } = useTranslation();
  useEffect(() => {

    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');
    // document.title = t('app_title'); 
  }, [currentLanguage, t]);
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <ScrollTo />
        <SotialFooter />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/home" replace />} />
            <Route path="/home" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/service" element={<Service />} />
            <Route path="/consultations" element={<Consultations />} />
            <Route path="/course" element={<Course />} />
            <Route path="/DirectorWriter" element={<DirectorWriter />} />
            <Route path="/ChairmansMessage" element={<ChairmansMessage />} />
            <Route path="/Vision" element={<Vision />} />
            <Route path="/course/:id" element={<NesrCourse />} />
            <Route path="/service/:id" element={<NestService />} />
          </Route>
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/signIn" element={<SignIn />} />
          <Route path="/forgetPassword" element={<ForgetPass />} />
          <Route path="/forgetPasswordJwt" element={<JWTForgetPass />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
