import { Box, Typography } from '@mui/material'
import React from 'react'
import './../../SignUp/FormSignUp.css'
import { useForm } from "react-hook-form";
import auth from './../../../../assets/Auth/Two factor authentication-bro (3).png'
// import "./ForgetPass.css"
import "./JWTForgetPass.css"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function JWTForgetPassComp() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const onSubmit = data => console.log(data);
    const { t } = useTranslation()
    return (
        <>
            <div className="form_sign_up text-center d-inline-flex p-3">
                <div className="container">
                    <div className="left_box">
                        <div className="d-flex flex-column align-items-center">
                            <img src={auth} alt="" className='img-fluid mt-4' style={{ margin: "10px 0 30px 0", height: "50vh" }} />
                            <div className="d-flex flex-column align-items-center" style={{ width: "100%" }}>
                                <form onSubmit={handleSubmit(onSubmit)} className='my_form'>
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3, width: "100%" }} alignItems={"center"} justifyContent={"space-evenly"} mt={2}>
                                        <input
                                            className='numIn'
                                            maxLength={1}
                                            {...register("numTokenOne", { required: true })}
                                            type="text" />
                                        <input
                                            className='numIn'
                                            maxLength={1}
                                            {...register("numTokenTow", { required: true })}
                                            type="text" />
                                        <input
                                            className='numIn'
                                            maxLength={1}
                                            {...register("numTokenThree", { required: true })}
                                            type="text" />
                                        <input
                                            className='numIn'
                                            maxLength={1}
                                            {...register("numTokenFour", { required: true })}
                                            type="text" />
                                    </Box>
                                    {errors.email && <span>This field is required</span>}
                                    <Typography sx={{ width: "100%" }} className="my_btn" mt={2}>
                                        <Link
                                            to="/resetPassword"
                                            className='link-offset-2 link-underline link-underline-opacity-0'
                                            style={{ color: "#000" }}>
                                            <button
                                                className="bg btn mt-1 text-center"
                                                style={{
                                                    width: "100%",
                                                    fontWeight: "bold",
                                                    borderRadius: "20px"
                                                }}>
                                                {t('next')}
                                            </button>
                                        </Link>
                                    </Typography>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JWTForgetPassComp