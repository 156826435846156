import { Typography } from '@mui/material'
import React from 'react'
import './../../SignUp/FormSignUp.css'
import EmailIcon from '@mui/icons-material/Email';
import { useForm } from "react-hook-form";
import logoSignUp from './../../../../assets/Auth/png لوجو.png'
import "./ForgetPass.css"
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as Yup from 'yup';
// import { useResetPassMutation } from "../../../../state/ApiAuth";
function ForgetPassComp() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    // const navigate = useNavigate();
    //   const { enqueueSnackbar } = useSnackbar();
    // const ResetPasswordSchema = Yup.object().shape({
    //     email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    // });

    // const methods = useForm({
    //     resolver: yupResolver(ResetPasswordSchema),
    // });

    //   const {
    //     handleSubmit,
    //     formState: { isSubmitting },
    //   } = methods;
    // const [resPass] = useResetPassMutation()
    const onSubmit = async (data) => {
        try {

            // let passWord = await resPass(data).unwrap()
            //   console.log("🚀 ~ file: ForgetPassComp.jsx:35 ~ onSubmit ~ passWord:", passWord)
            sessionStorage.setItem('email-recovery', data.email);
            // console.log("################################");
            //   enqueueSnackbar("Send Message to Email");
            // navigate(PATH_AUTH.newPassword);
        } catch (error) {
            console.log(error);
            //   enqueueSnackbar(error.data.message, {variant: 'error'});
        }
    };

    const { t } = useTranslation()

    return (
        <>
            <div className="form_sign_up text-center d-inline-flex p-3">
                <div className="container">
                    <div className="left_box">
                        <div className="d-flex flex-column align-items-center">
                            <img src={logoSignUp} alt="" className='img-fluid mt-4' />
                            <div className="my_box d-flex flex-column align-items-center">
                                <Typography variant='h3' mb={3} sx={{ color: "#B38840" }} >
                                    {t('auth.forgotPassword')}؟
                                </Typography>
                                <Typography variant='p' mb={3} sx={{ color: "#000", fontWeight: "bold" }} >
                                    {t('auth.entEmail')}
                                </Typography>
                                <form onSubmit={handleSubmit(onSubmit)} className='my_form'>
                                    <div className="input-group mb-3">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="name@example.com"
                                            {...register("email", { required: true })} />
                                        <span className="input-group-text" id="basic-addon1"><EmailIcon /></span>
                                    </div>
                                    {errors.email && <span>This field is required</span>}
                                    <Typography sx={{ width: "100%" }} className="my_btn" mt={2}>
                                        <button
                                            className="bg btn mt-1 text-center"
                                            style={{
                                                width: "100%",
                                                fontWeight: "bold",
                                            }}>
                                            <Link to="/forgetPasswordJwt"
                                                className='link-offset-2 link-underline link-underline-opacity-0'
                                                style={{ color: "#000" }}>
                                                {t('next')}
                                            </Link>
                                        </button>
                                    </Typography>
                                    <Typography
                                        mt={3}
                                        sx={{ fontWeight: "bold" }} >
                                        <Link
                                            to="/signIn"
                                            style={{ color: "#000" }}
                                            className='link-offset-2 link-underline link-underline-opacity-0'>
                                            {t('auth.backLogIn')}
                                        </Link>
                                    </Typography>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgetPassComp