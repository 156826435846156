import { Box, Typography } from '@mui/material'
import React from 'react'
import bgDoted from './../assets/CardService/nav.png'
import Title from '../components/homePage/Title'
import ListHome from '../components/homePage/ListHome'
import imgCard from './../assets/Home/her.jpeg.jpg'
import { useTranslation } from 'react-i18next'


function Vision() {
    const { t } = useTranslation()

    return (
        <div><Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" } }} className="d-flex homeV">
            <img src={bgDoted} alt="" className='imgDoted' />
            <div className="home card m-2">
                <div className="row g-0">
                    <div className="col-md-8">
                        <Title title={t('home.vision')} sx={{ fontWeight: 'bold' }} />
                        <div className="card-body">
                            <p className="card-text" style={{ fontWeight: 'bold' }} > {t('home.visionDesc')}  </p>
                        </div>
                        <Title title={t('home.mission')} style={{ fontWeight: 'bold' }} />
                        <div className="card-body">
                            <p className="card-text" style={{ fontWeight: 'bold' }} > {t('home.missionDesc')}</p>
                        </div>
                        <Title title={t('home.valuesGoals')} sx={{ fontWeight: 'bold' }} />
                        <div className="card-body">
                            <Typography sx={{ color: "#B38840", fontWeight: 'bold' }} variant='h4'>
                                {t('home.values')}:
                            </Typography>
                            <ListHome title={t('home.pioneering')} nestTitle={t('home.pioneeringComp')} />
                            <ListHome title={t('home.transparencyCredibility')} nestTitle={t('home.transparencyCredibilityComp')} />
                            <ListHome title={t('home.teamwork')} nestTitle={t('home.teamworkComp')} />
                            <ListHome title={t('home.honestyIntegrity')} nestTitle={t('home.honestyIntegrityComp')} />
                            <ListHome title={t('home.confidentiality')} nestTitle={t('home.confidentialityComp')} />
                        </div>
                        <div className="card-body">
                            <Typography sx={{ color: "#B38840", fontWeight: 'bold' }} variant='h4'>
                                {t('home.goals')}:
                            </Typography>
                            <Typography sx={{ color: "#B38840", fontWeight: 'bold' }} variant='p'>
                                {t('home.ourObjectivesTo')}
                            </Typography>
                            <ol style={{ fontWeight: 'bold' }}>
                                <li>{t('home.ourObjectivesTo1')}</li>
                                <li>{t('home.ourObjectivesTo2')}</li>
                                <li>{t('home.ourObjectivesTo3')}</li>
                                <li>{t('home.ourObjectivesTo4')}</li>
                                <li>{t('home.ourObjectivesTo5')}</li>
                                <li>{t('home.ourObjectivesTo6')}</li>
                                <li>{t('home.ourObjectivesTo7')}</li>
                                <li>{t('home.ourObjectivesTo8')}</li>
                            </ol>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={imgCard} className="img-fluid rounded-start" alt="..." />
                    </div>
                </div>
            </div>
        </Box></div>
    )
}

export default Vision