import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Avatar, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem } from "@mui/material";
// import SearchIcon from '@mui/icons-material/Search';
// import translateImg from "./../assets/translet.png"
import en from "./../assets/UK-Flag.webp"
import ar from "./../assets/png-transparent-flag-of-the-united-arab-emirates-flag-day-flag-of-japan-uae-flag-angle-flag-text-thumbnail.png"
import i18next from 'i18next';
// import TranslateIcon from '@mui/icons-material/Translate';
// import {
//     InputBase,
//     alpha,
//     styled
// } from '@mui/material';
import AvatarAccount from "./AvatarAccount";

export default function NavDrawer({ setDrawer, drawer }) {
    const [open, setOpen] = useState(false);
    // const Search = styled('div')(({ theme }) => ({
    //     position: 'relative',
    //     borderRadius: theme.shape.borderRadius,
    //     backgroundColor: alpha(theme.palette.common.black, 0.15),
    //     '&:hover': {
    //         backgroundColor: alpha(theme.palette.common.black, 0.25),
    //     },
    //     marginLeft: 0,
    //     [theme.breakpoints.up('sm')]: {
    //         marginLeft: theme.spacing(0),
    //         width: 'auto',
    //     },
    // }));
    // const StyledInputBase = styled(InputBase)(({ theme }) => ({
    //     color: 'inherit',
    //     '& .MuiInputBase-input': {
    //         padding: theme.spacing(1, 1, 1, 0),
    //         // vertical padding + font size from searchIcon
    //         paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    //         transition: theme.transitions.create('width'),
    //         width: '50%',
    //     },
    // }));

    const lngs = {
        en: { nativeName: <img src={en} alt='' style={{ width: '50px' }} /> },
        ar: { nativeName: <img src={ar} alt='' style={{ width: '50px' }} /> }
    };
    let lng = i18next.language
    const imagesPath = lng === 'en' ? en : ar
    const { t, i18n } = useTranslation()
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                        }}
                    >
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}>
                            <Link to="/home" className="nav-link" aria-current="page" onClick={() => setDrawer(false)} >{t('linksPages.home')}</Link>
                        </Link>
                        <Link
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Link to="/about" className="nav-link" onClick={() => setDrawer(false)} >{t('linksPages.aboutUs')}</Link>
                        </Link>
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Link to="/service" className="nav-link" aria-current="page" onClick={() => setDrawer(false)} >{t('linksPages.service')}</Link>
                        </Link>
                        <Link

                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Link to="/course" className="nav-link" onClick={() => setDrawer(false)}>{t('linksPages.sessions')}</Link>
                        </Link>
                        <Link

                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Link to="/consultations" className="nav-link" onClick={() => setDrawer(false)} >{t('linksPages.consulting')}</Link>
                        </Link>
                        <Link

                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}
                        >
                            <Link to="/contact" className="nav-link" onClick={() => setDrawer(false)}>{t('linksPages.contact')}</Link>
                        </Link>
                        <AvatarAccount />
                        {/* <Search sx={{ display: { md: "inherit", xs: "none" }, color: "#FFF" }}>
                            <StyledInputBase
                                placeholder="Search…"
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search> */}
                        {/* <TranslateIcon
                            sx={{ width: 30, height: 30, cursor: "pointer", color: "black", position: "absolute", left: '25px' }}
                            // src={translateImg}
                            onClick={(e) => setOpen(true)}
                        /> */}
                        <Avatar
                            // sx={{ width: 30, height: 30, cursor: "pointer", backgroundColor: 'transparent' }}
                            src={imagesPath}
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            sx={{ width: 30, height: 30, cursor: "pointer", color: "black", position: "absolute", left: '25px' }}
                            // src={translateImg}
                            onClick={(e) => setOpen(true)}
                        />
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            open={open}
                            onClick={(e) => setOpen(false)}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                        >
                            {Object.keys(lngs).map((lng) => (
                                <MenuItem key={lng}
                                    style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', zIndex: 99999999999999999999999999999999999999999999999999999, color: "#000" }}
                                    type="submit"
                                    value={lng}
                                    onClick={() => i18n.changeLanguage(lng)}>{lngs[lng].nativeName}</MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
