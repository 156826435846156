import React from 'react'
import "./AboutUs.css"
import Logo from "./../../assets/AllImages/7.jpg"
import hekl from "./../../assets/Organizational Structure.png"
import heklar from "./../../assets/الهيكل التنظيمي الريادة والإبداع عربي.png"
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import i18next from 'i18next'
import { Link } from 'react-router-dom'
import Title from '../homePage/Title'
// const styles = {
//   BoxOne: {
//     backgroundImage: `url(${hekl})`,
//     color: '#FFFFFF',


//   }
// }; 


function AboutUs() {

  const { t } = useTranslation()
  let lng = i18next.language
  const imagesPath = lng === 'en' ? hekl : heklar

  return (
    <>
      <div className="about_as">
        <div className="container">
          <Box sx={{ alignItems: 'center' }} className="row">
            <div className="col-md-6"  >
              <Title title={t('mainTitle')} color={'#b38840'} />
              {/* <h5 className="text2" style={{ fontWeight: 'bold' }}>{t('mainTitle')}</h5> */}
              <Typography variant="h5" sx={{ px: 2, py: 1, color: '#b38840', fontSize: { md: '1.5rem', xs: '1.25rem' } }}>
                {t('mainDesc')}
              </Typography>
              {/* <p className="info" style={{ fontWeight: 'bold' }}>{t('mainDesc')}</p> */}
              <p style={{ fontWeight: 'bold' }} >
                {t('aboutUs.descAbout')}
              </p>
            </div>
            <div className="col-md-6">
              <img src={Logo} alt="" className="img-fluid" height={500} style={{ borderRadius: '30px' }} />
            </div>
            <div className="col-md-12">
              <Typography variant='h4' sx={{ fontWeight: "bold", color: '#b38840' }}>
                {t('organizationalStructure')}
              </Typography>
              <hr style={{
                color: "#f9d990",
                opacity: "70%",
                borderTopWidth: "2px"
              }} />
              <Typography className="text-center">
                <a href={imagesPath} rel='noreferrer' target='_blank' >
                  <img
                    src={imagesPath}
                    style={{ width: "75%", height: "450px", margin: 2 }}
                    alt="hekl" target='_blank' className="img-fluid"
                  />
                </a>
              </Typography>
            </div>
          </Box>
        </div>
      </div>
    </>
  )
}

export default AboutUs