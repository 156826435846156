import React from 'react'
import AuthSignUp from '../components/Authentication/SignUp/AuthSignUp'
// import Navbar from '../components/Navbar'
import NavBar from "./../NavBar";
function SignUp() {
  return (
    <>
      {/* <Navbar /> */}
      <NavBar />
      <AuthSignUp />
    </>
  )
}

export default SignUp