import React from 'react'
import RightBoxAuth from './../global/RightBoxAuth'
import FormSignUp from './FormSignUp'
import { useTranslation } from 'react-i18next'
function AuthSignUp() {
  const { t } = useTranslation()
  return (
    <>
      <div className="d-flex align-items-center">
        <RightBoxAuth title={t('createAccount')} />
        <FormSignUp />
      </div>
    </>
  )
}

export default AuthSignUp