import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const DownFooter = () => {
    const { t } = useTranslation()

    return (
        <div className="down">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p className="text-center"
                            style={{
                                color: '#FFF',
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                        > {t('copR') + " | 2023"} </p>
                        <Link
                            to="https://revampbrands.com/"
                            style={{
                                color: '#FFF',
                                textDecoration: "none",
                                fontSize: 16,
                                fontWeight: "bold",
                            }}
                        >
                            <p className="text-center"> {t('dev')} </p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownFooter