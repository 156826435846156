import React from 'react'
import imgCard from './../../assets/Home/her.jpeg.jpg'
import Title from './Title'
import { Typography } from '@mui/material'
import ListHome from './ListHome'
import './CardInform.css'
import ListCardInform from './ListCardInform'
import { useTranslation } from 'react-i18next'
function CardInform() {
    const { t } = useTranslation()
    return (

        <div className="home card m-2">
            <div className="row g-0">
                <div className="col-md-8">
                    <Title title={t('home.vision')} />
                    <div className="card-body">
                        <p className="card-text" style={{ fontWeight: 'bold' }} > {t('home.visionDesc')}  </p>
                    </div>
                    <Title title={t('home.mission')} />
                    <div className="card-body">
                        <p className="card-text" style={{ fontWeight: 'bold' }} > {t('home.missionDesc')}</p>
                    </div>
                    <Title title={t('home.valuesGoals')} />
                    <div className="card-body" style={{ fontWeight: 'bold' }} >
                        <Typography sx={{ color: "#B38840", fontWeight: 'bold' }} variant='h4'>
                            {t('home.values')}:
                        </Typography>
                        <ListHome title={t('home.pioneering')} nestTitle={t('home.pioneeringComp')} />
                        <ListHome title={t('home.transparencyCredibility')} nestTitle={t('home.transparencyCredibilityComp')} />
                        <ListHome title={t('home.teamwork')} nestTitle={t('home.teamworkComp')} />
                        <ListHome title={t('home.honestyIntegrity')} nestTitle={t('home.honestyIntegrityComp')} />
                        <ListHome title={t('home.confidentiality')} nestTitle={t('home.confidentialityComp')} />
                    </div>
                    <div className="card-body">
                        <Typography sx={{ color: "#B38840", fontWeight: 'bold' }} variant='h4'>
                            {t('home.goals')}:
                        </Typography>
                        <Typography sx={{ color: "#B38840", fontWeight: 'bold' }} variant='p'>
                            {t('home.ourObjectivesTo')}
                        </Typography>
                        <ListCardInform
                            one={t('home.ourObjectivesTo1')}
                            tow={t('home.ourObjectivesTo2')}
                            three={t('home.ourObjectivesTo3')}
                            four={t('home.ourObjectivesTo4')}
                            five={t('home.ourObjectivesTo5')}
                            six={t('home.ourObjectivesTo6')}
                            seven={t('home.ourObjectivesTo7')}
                            eight={t('home.ourObjectivesTo8')}
                        />
                    </div>
                </div>
                <div className="col-md-4">
                    <img src={imgCard} className="img-fluid rounded-start" alt="..." />
                </div>
            </div>
        </div>
    )
}

export default CardInform