import { Box, Typography } from '@mui/material'
import React from 'react'
import logoSignUp from './../../../assets/Auth/png لوجو.png'
import './../SignUp/AuthSignUp.css'
import { useTranslation } from 'react-i18next'
function RightBoxAuth({ title }) {
    const { t } = useTranslation()
    return (
        <Box sx={{ display: { md: "inline-flex", xs: "none" }, textAlign: "center" }} className="sign_up p-3">
            <div className="right_box">
                <div className="">
                    <div className="d-flex flex-column align-items-center">
                        <img src={logoSignUp} alt="" className='img-fluid mt-4' />
                        <Typography variant='h4' mb={3} sx={{ color: "#B38840" }}>
                            {t('mainTitle')}
                        </Typography>
                        <Typography variant='h5' mb={6} sx={{ color: "#B38840", width: "100%" }}>
                            {t('mainDesc')}
                        </Typography>
                        <Typography variant='h4' mb={3} sx={{ color: "#FFFFFF" }}>
                            {title}
                        </Typography>
                        <Typography variant='p' mb={6} sx={{ color: "#FFFFFF", marginLeft: "9px", marginRight: "9px" }}>
                            {t('auth.welcome')}
                        </Typography>
                    </div>
                </div>
            </div>
        </Box>
    )
}

export default RightBoxAuth