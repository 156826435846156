import React from 'react'
// import Navbar from '../components/Navbar'
import AuthSignIn from '../components/Authentication/SignIn/AuthSignIn'
import NavBar from "./../NavBar";

function SignIn() {
  return (
    <>
      {/* <Navbar /> */}
      <NavBar />
      <AuthSignIn />
    </>
  )
}

export default SignIn