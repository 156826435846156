import React from 'react'
import { Outlet } from "react-router-dom";
import Foteer from "./../components/Footer/Foteer"
import { Box } from '@mui/material';
import NavBar from "./../NavBar";
function Layout() {
  return (
    <Box>
      <NavBar />
      <Outlet />
      <Foteer />
    </Box>
  )
}

export default Layout