import React from 'react'
import { Link } from 'react-router-dom'
import { Box } from '@mui/material'

const Sotial = () => {
    return (
        <>
            <div className="col-md-1" style={{ width: '1%' }} >
                <Box sx={{ display: 'flex', flexDirection: { md: 'column', xs: 'row' } }} className="photo">
                    <div className="br">
                        <Link to="https://www.facebook.com/100090821280991/posts/pfbid02knAiEfTGre9cn7eU6jHWygBSiy1MiPUHLkzDCkEMbThWScoXqofJRUAkv4wWzSTxl/?d=w&mibextid=DcJ9fc">
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.167" height="22.717" viewBox="0 0 12.167 22.717">
                                <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M12.979,12.778l.631-4.111H9.665V6a2.056,2.056,0,0,1,2.318-2.221h1.793V.278A21.87,21.87,0,0,0,10.593,0C7.344,0,5.221,1.969,5.221,5.534V8.667H1.609v4.111H5.221v9.939H9.665V12.778Z" transform="translate(-1.609)" fill="#f5d593" />
                            </svg>
                        </Link>
                    </div>
                    <div className="br">
                        <Link to="https://instagram.com/alriyada2023?igshid=YmMyMTA2M2Y=">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.157" height="23.152" viewBox="0 0 23.157 23.152">
                                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M11.576,7.878a5.936,5.936,0,1,0,5.936,5.936A5.926,5.926,0,0,0,11.576,7.878Zm0,9.795a3.859,3.859,0,1,1,3.859-3.859A3.866,3.866,0,0,1,11.576,17.672ZM19.139,7.635A1.384,1.384,0,1,1,17.754,6.25,1.381,1.381,0,0,1,19.139,7.635ZM23.07,9.04A6.852,6.852,0,0,0,21.2,4.189a6.9,6.9,0,0,0-4.851-1.87c-1.911-.108-7.641-.108-9.552,0A6.887,6.887,0,0,0,1.946,4.184,6.874,6.874,0,0,0,.076,9.035c-.108,1.911-.108,7.641,0,9.552a6.851,6.851,0,0,0,1.87,4.851A6.905,6.905,0,0,0,6.8,25.308c1.911.108,7.641.108,9.552,0a6.851,6.851,0,0,0,4.851-1.87,6.9,6.9,0,0,0,1.87-4.851c.108-1.911.108-7.635,0-9.547ZM20.6,20.638a3.907,3.907,0,0,1-2.2,2.2c-1.524.6-5.14.465-6.824.465s-5.305.134-6.824-.465a3.907,3.907,0,0,1-2.2-2.2c-.6-1.524-.465-5.14-.465-6.824s-.134-5.305.465-6.824a3.907,3.907,0,0,1,2.2-2.2c1.524-.6,5.14-.465,6.824-.465s5.305-.134,6.824.465a3.907,3.907,0,0,1,2.2,2.2c.6,1.524.465,5.14.465,6.824S21.205,19.119,20.6,20.638Z" transform="translate(0.005 -2.237)" fill="#f5d593" />
                            </svg>
                        </Link>
                    </div>
                </Box>
            </div>
        </>
    )
}

export default Sotial